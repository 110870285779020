import { useParams } from 'react-router-dom';
import Switch from 'common/components/Switch/Switch';

const Subscriptions = () => {
  const { id } = useParams();

  return (
    <div className="subscriptions__container">
      <h1 className="subscriptions__title">Manage your Subscriptions</h1>
      <div className="subscriptions__list">
        <div className="subscriptions__item">
          <div className="subscriptions__switch">
            <h2>Pinnacle News</h2> <Switch fixed />
          </div>
          <p className="subscriptions__description">
            This monthly newsletter focuses on the network's growth strategy and progress on various
            initiatives and accomplishments. An event calendar is included.
          </p>
          <span>You cannot unsubscribe from this newsletter</span>
        </div>
        <div className="subscriptions__item">
          <div className="subscriptions__switch">
            <h2>Network News</h2> <Switch fixed />
          </div>
          <p className="subscriptions__description">
            This monthly newsletter provides information on how our member firms reflect our Values,
            celebrates firm achievements, and offers examples of how member firms are Working as
            One.
          </p>
          <span>You cannot unsubscribe from this newsletter</span>
        </div>
        <div className="subscriptions__item">
          <div className="subscriptions__switch">
            <h2>Speed of Play</h2> <Switch />
          </div>
          <p className="subscriptions__description">
            This weekly newsletter shares relevant information about the rapid pace of change with
            Artificial Intelligence and available tools and solutions as well as updates on network
            AI-related events and opportunities.
          </p>
        </div>
        <div className="subscriptions__item">
          <div className="subscriptions__switch">
            <h2>ESG News</h2> <Switch />
          </div>
          <p className="subscriptions__description">
            This bi-weekly newsletter features a range of information about Environmental, Social,
            and Governance topics, including regulatory measures, research, events, and network
            progress.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
