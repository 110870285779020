import { useState } from 'react';

const Switch = ({ fixed }) => {
  const [checked, setChecked] = useState(false);

  return (
    <label className="switch">
      <input
        type="checkbox"
        onClick={() => setChecked(!checked)}
        checked={fixed ? true : checked}
      />
      <span className={`slider ${fixed ? 'slider--fixed' : ''} round`}></span>
    </label>
  );
};

export default Switch;
